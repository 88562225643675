import {
  AccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  styled,
} from "@mui/material";
import Icons from "../../Icons";

interface FormAccordionSummaryProps extends AccordionSummaryProps {
  color?: "primary" | "secondary";
}

const FormAccordionSummary = styled(
  (props: FormAccordionSummaryProps) => (
    <AccordionSummary
      expandIcon={<Icons.ArrowIcon arrowType="empty" arrowDirection="down" />}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== "color",
  }
)<FormAccordionSummaryProps>(({ theme, color = "secondary" }) => ({
  backgroundColor:
    color === "primary"
      ? theme.palette.primary.main
      : theme.palette.secondary.main,
  maxWidth: "100%",
  minHeight: theme.spacing(5),
  [`&.${accordionSummaryClasses.expanded}`]: {
    minHeight: theme.spacing(5),
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    wordWrap: "break-word",
    wordBreak: "break-all",
    [`&.${accordionSummaryClasses.expanded}`]: {
      margin: "8px 0",
    },
  },
  color:
    color === "primary"
      ? theme.palette.primary.contrastText
      : theme.palette.secondary.contrastText,
  fontSize: "1rem",
  fontWeight: "bold",
  svg: {
    path: {
      fill:
        color === "primary"
          ? theme.palette.primary.contrastText
          : theme.palette.secondary.contrastText,
    },
  },
}));

export default FormAccordionSummary;
