import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Language } from "@neurosolutionsgroup/models";
import LandingAssets from "assets/landing";
import "./PharmacyPartner.scss";
import { useAnalytics } from "@neurosolutionsgroup/analytics";
import { useEffect } from "react";

const PharmacyPartner = (): JSX.Element => {
  document.title = "Kairos x Accès pharma";

  const {
    functions: { pageView },
  } = useAnalytics();
  const history = useHistory();
  const { t, i18n } = useTranslation(["webappLanding"]);
  const promoCode = "ACCESPHARMA2025";

  useEffect(() => {
    pageView(window.location.pathname + window.location.search);
  }, []);

  const changeLanguage = (lng: Language) => {
    i18n.changeLanguage(lng);
  };

  const onClick = () => {
    history.push({
      pathname: "/account",
      search: `?promoCode=${promoCode}&autoCheckout=true`,
    });
  };

  return (
    <div className="pharmacy-partner">
      <div className="background-image-top desktop-only-image">
        <img
          src={LandingAssets.FamilyPhoto}
          srcSet={`${LandingAssets.FamilyPhoto} 1x, ${LandingAssets.FamilyPhoto2x} 2x, ${LandingAssets.FamilyPhoto3x} 3x`}
          alt="Dad and daughter"
          className="main-image"
        />
        <div className="left-floating-icons">
          <img src={LandingAssets.IconClock} alt="Purple clock" />
        </div>
        <div className="bottom-floating-icons">
          <img
            src={LandingAssets.IconsRoutine}
            srcSet={`${LandingAssets.IconsRoutine} 1x, ${LandingAssets.IconsRoutine2x} 2x, ${LandingAssets.IconsRoutine3x} 3x`}
            alt="3 Icons representing a school bus, a calendar and a toothbrush"
          />
        </div>
      </div>

      <div className="content-wrapper">
        <div className="header">
          <div className="logos">
            <img
              src={LandingAssets.KairosLogo}
              srcSet={`${LandingAssets.KairosLogo} 1x, ${LandingAssets.KairosLogo2x} 2x, ${LandingAssets.KairosLogo3x} 3x`}
              alt="Kairos"
              className="kairos-logo"
            />
            <img
              src={
                i18n.language === "fr"
                  ? LandingAssets.AccespharmaLogoFR
                  : LandingAssets.AccespharmaLogoEN
              }
              srcSet={`${LandingAssets.AccespharmaLogoFR} 1x, ${LandingAssets.AccespharmaLogoFR} 2x, ${LandingAssets.AccespharmaLogoFR} 3x, ${LandingAssets.AccespharmaLogoEN} 1x, ${LandingAssets.AccespharmaLogoEN} 2x, ${LandingAssets.AccespharmaLogoEN} 3x`}
              alt="Accèspharma chez Walmart"
              className="accespharma-logo"
            />
          </div>
          <button
            onClick={() => changeLanguage(i18n.language === "fr" ? "en" : "fr")}
            className="language-button"
          >
            {i18n.language === "fr" ? "English" : "Français"}
          </button>
        </div>

        <div className="main-content">
          <h1 className="title">
            <span>{t("landing.title")}</span>
          </h1>
          <p className="description">{t("landing.introduction.part1")}</p>
          <p className="description">{t("landing.introduction.part2")}</p>
          <p className="highlight">{t("landing.introduction.part3")}</p>
          <div className="divider"></div>
        </div>

        <div className="background-image-middle">
          <img
            src={LandingAssets.IconsFollowUp}
            srcSet={`${LandingAssets.IconsFollowUp} 1x, ${LandingAssets.IconsFollowUp2x} 2x, ${LandingAssets.IconsFollowUp3x} 3x`}
            alt="3 Icons representing a todo list, a graph and a note book"
            className="left-floating-icons"
          />
          <img
            src={LandingAssets.PolygonPointDown}
            alt="PolygonShape"
            className="left-polygon"
          />
        </div>

        <div className="premium-offer">
          <p className="offer-text">
            {t("landing.partnerContent.accesPharma")}
          </p>
          <button onClick={onClick} className="cta-button">
            {t("landing.button.accesPharma")}
          </button>
          <p className="disclaimer">{t("landing.disclaimer.accesPharma")}</p>
        </div>

        <div className="benefits-section">
          <div className="row benefits-content">
            <div className="col-12 col-lg-4 hero-image-left">
              <img
                src={LandingAssets.AvatarSuperhero}
                srcSet={`${LandingAssets.AvatarSuperhero} 1x, ${LandingAssets.AvatarSuperhero2x} 2x, ${LandingAssets.AvatarSuperhero3x} 3x`}
                alt="Avatar Superhero"
              />
            </div>
            <div className="col-12 col-lg-4 benefits-text">
              <h2>{t("landing.appvalue.title")}</h2>
              <ul className="benefits-list">
                <li>
                  <span className="bullet" />
                  {t("landing.appvalue.content.part1")}
                </li>
                <li>
                  <span className="bullet" />
                  {t("landing.appvalue.content.part2")}
                </li>
                <li>
                  <span className="bullet" />
                  {t("landing.appvalue.content.part3")}
                </li>
                <li>
                  <span className="bullet" />
                  {t("landing.appvalue.content.part4")}
                </li>
                <li>
                  <span className="bullet" />
                  {t("landing.appvalue.content.part5")}
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 hero-image-right">
              <img
                src={LandingAssets.AvatarKairosJump}
                srcSet={`${LandingAssets.AvatarKairosJump} 1x, ${LandingAssets.AvatarKairosJump2x} 2x, ${LandingAssets.AvatarKairosJump3x} 3x`}
                alt="Avatar Kairos jumping"
              />
            </div>
          </div>

          <div className="background-image-bottom">
            <img
              src={LandingAssets.PolygonPointUp}
              alt="PolygonShape"
              className="bottom-polygon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmacyPartner;
