export enum RevenueCatEvent {
  BillingIssue = "BILLING_ISSUE",
  Cancellation = "CANCELLATION",
  Expiration = "EXPIRATION",
  InitialPurchase = "INITIAL_PURCHASE",
  NonRenewingPurchase = "NON_RENEWING_PURCHASE",
  ProductChange = "PRODUCT_CHANGE",
  Renewal = "RENEWAL",
  SubscriptionExtended = "SUBSCRIPTION_EXTENDED",
  SubscriptionPaused = "SUBSCRIPTION_PAUSED",
  Test = "TEST",
  Transfer = "TRANSFER",
  Uncancellation = "UNCANCELLATION",
}
