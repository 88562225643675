import { Route, Switch, useLocation } from "react-router";
import PharmacyPartner from "./PharmacyPartner";

const ErrorPage = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">Access Denied</h1>
      <p className="text-gray-600">Invalid or missing promotion code.</p>
    </div>
  );
};

const LandingPage = (): JSX.Element => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const promotionCode = searchParams.get("promotion");
  const hasValidPromotion = promotionCode === "accespharma2025";

  return (
    <Switch>
      <Route
        path="/"
        component={hasValidPromotion ? PharmacyPartner : ErrorPage}
      />
    </Switch>
  );
};

export default LandingPage;
