import {
  AnalyticService,
  HandleEventFunction,
  OnUserLoginFunction,
  PageViewFunction,
  SetProfilePropertiesFunction,
  TrackEventFunction,
} from "@neurosolutionsgroup/analytics";
import { Tools } from "@neurosolutionsgroup/tools";
import { FirebaseApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import gaStringTransformer, {
  gaTransformObjectProperties,
} from "./gaStringTransformer";

class FirebaseAnalyticsService implements AnalyticService {
  private app: FirebaseApp;
  private analytics: Analytics | null = null;
  private location: "webviews" | "neuroportal" | "webapp";

  private initWarning =
    "Firebase analytics service called but was not initialised.";

  constructor(
    app: FirebaseApp,
    location: "webviews" | "neuroportal" | "webapp" = "webviews"
  ) {
    this.app = app;
    this.location = location;
  }

  public name = "Firebase";

  public init = () => {
    this.analytics = getAnalytics(this.app);
  };

  private onUserLogin: OnUserLoginFunction = (
    distinctId,
    language,
    email,
    created
  ) => {
    if (this.analytics) {
      setUserId(this.analytics, distinctId);

      this.setProfileProperties({
        email,
        language,
        created_at: created,
        timezone: Tools.Time.Dates.getTimezone(),
      });
    } else {
      console.warn(this.initWarning);
    }
  };

  private pageView: PageViewFunction = (path) => {
    if (this.analytics) {
      logEvent(this.analytics, "screen_view", {
        firebase_screen: path,
        firebase_screen_class: this.location,
      });
    } else {
      console.warn(this.initWarning);
    }
  };

  private setProfileProperties: SetProfilePropertiesFunction = (properties) => {
    const correctedProperties = gaTransformObjectProperties(properties);

    if (this.analytics) {
      setUserProperties(this.analytics, correctedProperties);
    } else {
      console.warn(this.initWarning);
    }
  };

  private setOnceProfileProperties: SetProfilePropertiesFunction = () => {};

  private trackEvent: TrackEventFunction = (eventName, properties) => {
    const correctedEventName = gaStringTransformer(eventName);

    let correctedProperties: Record<string, unknown> | undefined = undefined;

    if (properties) {
      correctedProperties = gaTransformObjectProperties(properties);
    }

    if (this.analytics) {
      logEvent(this.analytics, correctedEventName, correctedProperties);
    } else {
      console.warn(this.initWarning);
    }
  };

  public functions = {
    onUserLogin: this.onUserLogin,
    pageView: this.pageView,
    setProfileProperties: this.setProfileProperties,
    setOnceProfileProperties: this.setOnceProfileProperties,
    trackEvent: this.trackEvent,
  };

  public handleEvent: HandleEventFunction = (event) => {
    if (this.analytics) {
      this.trackEvent(event.name, event.eventProperties);

      if (event.setProperties) {
        this.setProfileProperties(event.setProperties);
      }

      if (event.setOnceProperties) {
        this.setProfileProperties(event.setOnceProperties);
      }
    } else {
      console.warn(this.initWarning);
    }
  };
}

export const getFirebaseAnalyticsService = (
  app: FirebaseApp,
  location?: "webviews" | "neuroportal" | "webapp"
): AnalyticService => {
  return new FirebaseAnalyticsService(app, location);
};
