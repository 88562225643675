import React, { FunctionComponent } from "react";
import { useField } from "formik";
import FormGroup from "../FormGroup/FormGroup";
import { CypressProps } from "@neurosolutionsgroup/models";

/**
 * TextInput properties.
 */
export interface TextInputProps extends CypressProps {
  /** HTML name of the input */
  name: string;
  /** HTML input type */
  type: "text" | "email" | "tel" | "url";
  /** Text label to be shown with the input */
  label?: string;
  dataTestId?: string;
}

/**
 * Simple text input.
 */
const TextInput: FunctionComponent<TextInputProps> = ({
  name,
  type,
  label,
  dataTestId = "textinput-input",
  ...props
}: TextInputProps) => {
  const [field, meta] = useField(name);

  return (
    <FormGroup
      label={label}
      name={field.name}
      showValidation={!(!meta.touched || !meta.error)}
      validationMessage={meta.error}
    >
      <input
        data-testid={dataTestId}
        type={type}
        {...field}
        className="form__input--text"
        data-cy={props["data-cy"]}
      />
    </FormGroup>
  );
};

export default TextInput;
