import AuthError, { AuthErrorCode } from "../../commons/AuthError/AuthError";
import { AuthHookResult } from "../../commons/AuthHookResult/AuthHookResult";
import { CompleteRegistrationArgs } from "../../types";
import {
  getAuth,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import {
  FirestoreCollection,
  RegisterCompleteUserBody,
} from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

interface UseRegistrationActions {
  registerByEmail: (args: RegisterCompleteUserBody) => Promise<UserCredential>;
  completeRegistration: (args: CompleteRegistrationArgs) => Promise<boolean>;
  registerCompleteUserByEmail: (
    args: RegisterCompleteUserBody
  ) => Promise<UserCredential>;
}

const useRegistration = (): AuthHookResult<
  Record<string, never>,
  UseRegistrationActions
> => {
  /**
   * Register a Firebase user with email and password. On registration success the profile language and contact email will be updated.
   * @param args @type {EmailRegistrationArgs} Registration information, including language.
   */
  const registerByEmail = async (
    args: RegisterCompleteUserBody
  ): Promise<UserCredential> => {
    const auth = getAuth();

    // Register user with custom function.
    await FirebaseAPI.Account.registerUserSimple(args);

    // Authenticate user.
    return signInWithEmailAndPassword(auth, args.email, args.password);
  };

  /**
   * Register a Firebase user with email and password. On registration success the profile language and contact email will be updated.
   * Includes firstName and lastName for referral purposes.
   * @param args @type {EmailRegistrationArgs} Registration information, including language.
   */
  const registerCompleteUserByEmail = async (
    args: RegisterCompleteUserBody
  ): Promise<UserCredential> => {
    const auth = getAuth();

    if (args.firstName) {
      // Register user with custom function.
      return FirebaseAPI.Account.registerUser(args).then(() => {
        // Authenticate user.
        return signInWithEmailAndPassword(auth, args.email, args.password).then(
          (credentials) => {
            if (args.firstName) {
              return completeRegistration({
                name: args.firstName,
              }).then(() => {
                return credentials;
              });
            } else {
              return Promise.reject(new Error("Missing name."));
            }
          }
        );
      });
    } else {
      return Promise.reject(new Error("Missing name."));
    }
  };

  /**
   * Complete the registration of a user, updating the users personal information.
   * @param args @type {CompleteRegistrationArgs} The account information to complete the registration with.
   * @returns {boolean} Success status of request.
   */
  const completeRegistration = async (
    args: CompleteRegistrationArgs
  ): Promise<boolean> => {
    const db = getFirestore();
    const auth = getAuth();

    if (auth.currentUser) {
      const userDocRef = doc(
        db,
        FirestoreCollection.Users,
        auth.currentUser.uid
      );

      return updateDoc(userDocRef, {
        firstName: args.name,
      }).then(() => {
        return true;
      });
    } else {
      return Promise.reject(
        new AuthError(
          AuthErrorCode.Unauthenticated,
          "No Firebase user found in currentUser."
        )
      );
    }
  };

  return {
    selectors: {},
    actions: {
      registerByEmail,
      completeRegistration,
      registerCompleteUserByEmail,
    },
  };
};

export default useRegistration;
