import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import CardPage from "components/layout/pages/CardPage";
import LogoCard from "components/layout/cards/LogoCard";
import { SuspenseLoader } from "components/graphics";
import { dangerNotification } from "components/notifications";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";
import { CompleteRegistrationPage, AuthError } from "services/authentication";
import {
  WebAppRegistrationCompleted,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import queryString from "query-string";
import useUserProfile from "hooks/account/useUserProfile";

const Component = () => {
  const history = useHistory();
  const { t } = useTranslation(["webappBase"]);
  const location = useLocation();
  const urlParams = location.search;

  const {
    actions: { setName },
  } = useUserProfile();

  const params = queryString.parse(urlParams);

  const name = params.name ? params.name.toString() : undefined;

  const { handleEvent } = useAnalytics();

  const onSuccess = (name: string) => {
    const event: WebAppRegistrationCompleted = {
      name: "Web App Registration Completed",
      setProperties: {
        name,
      },
      setOnceProperties: {
        "Account Completed On": new Date().toISOString(),
      },
    };

    handleEvent(event);

    setName(name);

    history.push({ pathname: "/app/dashboard", search: urlParams });
  };
  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms.errors.title"),
        t("forms.errors.general") + ": " + t("forms.errors." + err.code),
        false
      )
    );
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <CompleteRegistrationPage
          onSuccess={onSuccess}
          onFailure={onFailure}
          name={name}
        />
      </LogoCard>
    </CardPage>
  );
};

export default function CompleteRegistration(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
