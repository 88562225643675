import KairosLogo from "./kairos-logo.png";
import KairosLogo2x from "./kairos-logo@2x.png";
import KairosLogo3x from "./kairos-logo@3x.png";
import AccespharmaLogoEN from "./accespharma-walmart-logo-en.png";
import AccespharmaLogoFR from "./accespharma-walmart-logo-en.png";
import FamilyPhoto from "./landing_family_photo.png";
import FamilyPhoto2x from "./landing_family_photo@2x.png";
import FamilyPhoto3x from "./landing_family_photo@3x.png";
import IconClock from "./landing_icon_clock.svg";
import IconsFollowUp from "./landing_icons_follow_up.png";
import IconsFollowUp2x from "./landing_icons_follow_up@2x.png";
import IconsFollowUp3x from "./landing_icons_follow_up@3x.png";
import IconsRoutine from "./landing_icons_routine.png";
import IconsRoutine2x from "./landing_icons_routine@2x.png";
import IconsRoutine3x from "./landing_icons_routine@3x.png";
import AvatarKairosJump from "./avatar_kairos_jump.png";
import AvatarKairosJump2x from "./avatar_kairos_jump@2x.png";
import AvatarKairosJump3x from "./avatar_kairos_jump@3x.png";
import AvatarSuperhero from "./avatar_superhero.png";
import AvatarSuperhero2x from "./avatar_superhero@2x.png";
import AvatarSuperhero3x from "./avatar_superhero@3x.png";
import PolygonPointUp from "./landing_polygon_bottom.svg";
import PolygonPointDown from "./landing_polygon_side.svg";

const LandingAssets = {
  KairosLogo,
  KairosLogo2x,
  KairosLogo3x,
  AccespharmaLogoEN,
  AccespharmaLogoFR,
  FamilyPhoto,
  FamilyPhoto2x,
  FamilyPhoto3x,
  IconClock,
  IconsFollowUp,
  IconsFollowUp2x,
  IconsFollowUp3x,
  IconsRoutine,
  IconsRoutine2x,
  IconsRoutine3x,
  AvatarKairosJump,
  AvatarKairosJump2x,
  AvatarKairosJump3x,
  AvatarSuperhero,
  AvatarSuperhero2x,
  AvatarSuperhero3x,
  PolygonPointUp,
  PolygonPointDown,
};

export default LandingAssets;
