export interface ApiError {
  code: number;
  message: string;
  context: {
    namespace: string;
    errorCode?: number | string;
    [key: string]: unknown;
  };
  stack?: string;
}

export enum ErrorCode {
  EmailAlreadyTaken = 40900,
  InvalidActivationCode = 40500,
}

export const isApiError = (value: unknown): value is ApiError => {
  return (
    typeof value === "object" &&
    value !== null &&
    "code" in value &&
    "message" in value &&
    "context" in value
  );
};
