import React from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "react-bootstrap";

import { SuspenseLoader } from "components/graphics";
import DashboardAssets from "assets/dashboard";
import useLanguage from "hooks/useLanguage";
import { Tools } from "@neurosolutionsgroup/tools";
import appStoreAssets from "assets/appstore";

const Component = () => {
  //  Hooks  //
  //  Localization.
  const { t } = useTranslation(["webappDashboard", "webappBase"]);
  const { language } = useLanguage();

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="w-100">
        <h3>{t("messageNoData")}</h3>
      </div>
      <img
        src={Tools.Language.languageSwitch(language, {
          en: DashboardAssets.StartEN,
          fr: DashboardAssets.StartFR,
        })}
        style={{
          maxHeight: "50vh",
          maxWidth: "100%",
        }}
        alt="kairos-demo"
      />
      <Row className="align-items-center justify-content-center no-gutters mt-3">
        <Col>
          <a
            href={`https://kairos.app.link/hvKZt0EXJ7?lang=${language}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={t("webappBase:components.googleplayalt")}
              className="store-image"
              src={Tools.Language.languageSwitch(language, {
                en: appStoreAssets.playStoreEN,
                fr: appStoreAssets.playStoreFRCA,
              })}
            />
          </a>
        </Col>
        <Col>
          <a
            href={`https://kairos.app.link/hvKZt0EXJ7?lang=${language}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={t("webappBase:components.appstorealt")}
              className="px-4 store-image"
              src={Tools.Language.languageSwitch(language, {
                en: appStoreAssets.appStoreEN,
                fr: appStoreAssets.appStoreFRCA,
              })}
            />
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default function NoChildPlaceholder(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
