import appStoreEN from "./Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import appStoreFRCA from "./Download_on_the_App_Store_Badge_FRCA_RGB_blk_100517.svg";
import playStoreEN from "./GetItOnGooglePlay_Badge_Web_color_English.png";
import playStoreFRCA from "./GetItOnGooglePlay_Badge_Web_color_French-CA.png";

const appStoreAssets = {
  appStoreEN,
  appStoreFRCA,
  playStoreEN,
  playStoreFRCA,
};

export default appStoreAssets;
