import { HookResult } from "hooks/HookResult";
import { useContext } from "react";
import UserProfileContext from "./UserProfileContext";
import {
  CompleteAccountInfo,
  SubscriptionStatus,
} from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import {
  ProfileInfoUpdated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import FirestoreService from "services/FirestoreService";

interface UseUserProfileSelectors {
  userProfile: CompleteAccountInfo | undefined;
  subscriptionStatus: SubscriptionStatus | null;
  dataFetched: boolean;
}

interface UseUserProfileActions {
  updateUserProfile: (args: UserInternalData) => Promise<boolean>;
  sendConfirmationEmail: () => Promise<boolean>;
  resetPin: (pin: string) => Promise<boolean>;
  updateLanguage: (language: string) => Promise<string>;
  setIsSubscribed: VoidFunction;
  setName: (name: string) => void;
}

type UserInternalData = {
  firstName: string;
  email: string;
};

const useUserProfile = (): HookResult<
  UseUserProfileSelectors,
  UseUserProfileActions
> => {
  const {
    userProfile,
    setUserProfile,
    subscriptionStatus,
    setSubscriptionStatus,
    dataFetched,
  } = useContext(UserProfileContext);

  const { handleEvent } = useAnalytics();

  const KNOWN_ERRORS = ["2100"];

  const updateUserProfile = async (
    args: UserInternalData
  ): Promise<boolean> => {
    return FirebaseAPI.Account.putUserInfo({
      termsAccepted: true,
      lastName: "",
      city: "None",
      ...args,
    }).then(() => {
      const event: ProfileInfoUpdated = {
        name: "Profile Info Updated",
        setProperties: {
          email: args.email,
          name: args.firstName,
        },
      };

      handleEvent(event);

      setUserProfile((current) => ({
        ...current,
        ...args,
        isRegistered: current?.isRegistered ?? false,
        isFreeTrial: current?.isFreeTrial ?? false,
        isSubscribed: current?.isSubscribed ?? false,
        isStripe: current?.isStripe ?? false,
      }));

      return true;
    });
  };

  const sendConfirmationEmail = async (): Promise<boolean> => {
    console.error("Not implemented.");
    return false;
    // return API.Account.Security.sendConfirmationEmail().then(() => true);
  };

  const resetPin = async (pin: string): Promise<boolean> => {
    return FirestoreService.Account.updateParentPin(pin)
      .then(() => true)
      .catch((error) => {
        const errorMessage = error as string;

        if (KNOWN_ERRORS.includes(errorMessage)) {
          return Promise.reject(new Error(errorMessage));
        } else {
          return Promise.reject(new Error("unkown"));
        }
      });
  };

  const updateLanguage = async (language: string): Promise<string> => {
    return FirestoreService.Account.updateProfileLanguage(language)
      .then(() => {
        return language;
      })
      .catch((error) => {
        const errorMessage = error as string;

        if (KNOWN_ERRORS.includes(errorMessage)) {
          return Promise.reject(new Error(errorMessage));
        } else {
          return Promise.reject(new Error("unknown"));
        }
      });
  };

  const setIsSubscribed = () => {
    setSubscriptionStatus((current) =>
      current
        ? {
            ...current,
            isSubscribed: true,
          }
        : {
            isSubscribed: true,
            isFreeTrial: false,
            nextExpiration: null,
            hasPreviousSubscription: false,
            store: "stripe",
            previousStores: [],
            lastDowngradeExecution: null,
          }
    );
    setUserProfile((current) =>
      current
        ? {
            ...current,
            isSubscribed: true,
            isStripe: true,
            isFreeTrial: false,
          }
        : current
    );
  };

  const setName = (name: string) => {
    setUserProfile((current) =>
      current
        ? {
            ...current,
            firstName: name,
          }
        : current
    );
  };

  return {
    selectors: {
      userProfile,
      subscriptionStatus,
      dataFetched,
    },
    actions: {
      updateUserProfile,
      sendConfirmationEmail,
      resetPin,
      updateLanguage,
      setIsSubscribed,
      setName,
    },
  };
};

export default useUserProfile;
