import { UserCredential } from "firebase/auth";
import { useState } from "react";
import AuthError from "../commons/AuthError/AuthError";
import useRegistration from "../hooks/useRegistration/useRegistration";
import RegistrationPageComponent from "./RegistrationPageComponent";
import { useTranslation } from "react-i18next";
import { RegisterCompleteUserBody } from "@neurosolutionsgroup/models";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";

interface Props {
  onSuccess: (response: UserCredential, name?: string) => void;
  onFailure: (err: AuthError) => void;
  referrerId?: string;
}

const RegistrationPage = ({
  onSuccess,
  onFailure,
  referrerId,
}: Props): JSX.Element => {
  const { i18n } = useTranslation(["webappAuthentication"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    actions: { registerByEmail, registerCompleteUserByEmail },
  } = useRegistration();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const onSubmit = async (email: string, password: string, name?: string) => {
    setIsLoading(true);

    const createDefaultChild = checkFeatureFlagVersion(
      WebviewsFeatureFlag.DefaultChildCreation,
      "0.0.0" // Using minimum version as webapp has no version, effectively makes it boolean.
    );

    if (referrerId) {
      const data: RegisterCompleteUserBody = {
        email: email,
        password: password,
        language: i18n.language,
        referrerId: referrerId,
        firstName: name,
        createDefaultChild,
      };
      registerCompleteUserByEmail(data)
        .then((response) => {
          onSuccess(response, name);
        })
        .catch((err: AuthError) => {
          onFailure(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const data: RegisterCompleteUserBody = {
        email: email,
        password: password,
        language: i18n.language,
        referrerId: referrerId,
        firstName: name,
        createDefaultChild,
      };

      registerByEmail(data)
        .then((response) => {
          onSuccess(response, name);
        })
        .catch((err: AuthError) => {
          onFailure(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <RegistrationPageComponent
      isLoading={isLoading}
      onSubmit={onSubmit}
      referral={!!referrerId}
    />
  );
};
export default RegistrationPage;
