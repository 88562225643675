export * as en from "./en";

import authFR from "./fr/auth.json";
import coachFR from "./fr/coach.json";
import dashboardFR from "./fr/dashboard.json";
import ftueFR from "./fr/ftue.json";
import generalFR from "./fr/general.json";
import journalFR from "./fr/journal.json";
import medicationFR from "./fr/medication.json";
import navigationFR from "./fr/navigation.json";
import portalFR from "./fr/portal.json";
import portalResourcesFR from "./fr/portalResources.json";
import routineFR from "./fr/routine.json";
import settingsFR from "./fr/settings.json";
import templatesFR from "./fr/templates.json";
import validationFR from "./fr/validation.json";
import webappAuthenticationFR from "./fr/webappAuthentication.json";
import webappBaseFR from "./fr/webappBase.json";
import webappBillingFR from "./fr/webappBilling.json";
import webappDashboardFR from "./fr/webappDashboard.json";
import webappLandingFR from "./fr/webappLanding.json";
import webappSettingsFR from "./fr/webappSettings.json";

const fr = {
  auth: authFR,
  coach: coachFR,
  dashboard: dashboardFR,
  ftue: ftueFR,
  general: generalFR,
  journal: journalFR,
  medication: medicationFR,
  navigation: navigationFR,
  portal: portalFR,
  portalResources: portalResourcesFR,
  routine: routineFR,
  settings: settingsFR,
  templates: templatesFR,
  validation: validationFR,
  webappAuthentication: webappAuthenticationFR,
  webappBase: webappBaseFR,
  webappBilling: webappBillingFR,
  webappDashboard: webappDashboardFR,
  webappLanding: webappLandingFR,
  webappSettings: webappSettingsFR
}

import authDE from "./de/auth.json";
import coachDE from "./de/coach.json";
import dashboardDE from "./de/dashboard.json";
import ftueDE from "./de/ftue.json";
import generalDE from "./de/general.json";
import journalDE from "./de/journal.json";
import medicationDE from "./de/medication.json";
import navigationDE from "./de/navigation.json";
import routineDE from "./de/routine.json";
import settingsDE from "./de/settings.json";
import templatesDE from "./de/templates.json";
import validationDE from "./de/validation.json";
import webappAuthenticationDE from "./de/webappAuthentication.json";
import webappBaseDE from "./de/webappBase.json";
import webappBillingDE from "./de/webappBilling.json";
import webappDashboardDE from "./de/webappDashboard.json";
import webappSettingsDE from "./de/webappSettings.json";

const de = {
  auth: authDE,
  coach: coachDE,
  dashboard: dashboardDE,
  ftue: ftueDE,
  general: generalDE,
  journal: journalDE,
  medication: medicationDE,
  navigation: navigationDE,
  routine: routineDE,
  settings: settingsDE,
  templates: templatesDE,
  validation: validationDE,
  webappAuthentication: webappAuthenticationDE,
  webappBase: webappBaseDE,
  webappBilling: webappBillingDE,
  webappDashboard: webappDashboardDE,
  webappSettings: webappSettingsDE
}

export { de, fr };
