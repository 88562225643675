import { useAuth } from "services/authentication";
import { ProtectedRoute } from "components/navigation";
import Account from "pages/account/Account";
import ParentApp from "pages/app/ParentApp";
import Callbacks from "features/callbacks/Callbacks";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Download from "pages/download/Download";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "@neurosolutionsgroup/analytics";
import { Tools } from "@neurosolutionsgroup/tools";
import LandingPage from "pages/landing/LandingPage";

const AppRouter: React.FC = () => {
  const location = useLocation();
  const urlParams = location.search;
  const { i18n } = useTranslation();

  const {
    selectors: { auth },
  } = useAuth();

  const {
    functions: { onUserLogin },
  } = useAnalytics();

  useEffect(() => {
    if (auth && auth.currentUser) {
      const created = auth.currentUser.metadata.creationTime
        ? Tools.Time.Dates.getTimeStamp(
            new Date(auth.currentUser.metadata.creationTime)
          )
        : undefined;

      onUserLogin(
        auth.currentUser.uid,
        i18n.language,
        auth.currentUser.email ?? undefined,
        created
      );
    }
  }, [auth]);

  return (
    <Switch>
      <Route path="/account" component={Account} />
      <ProtectedRoute path="/app" component={ParentApp} />
      <Route path="/callbacks" component={Callbacks} />
      <Route path="/download" component={Download} />
      <Route path="/landing" component={LandingPage} />
      <Redirect
        to={{
          pathname: "/account",
          search: urlParams,
        }}
      />
    </Switch>
  );
};

export default AppRouter;
