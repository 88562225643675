import { useField } from "formik";
import React, { FunctionComponent, useState } from "react";
import clsx from "clsx";
import FormGroup from "../FormGroup/FormGroup";
import { CypressProps } from "@neurosolutionsgroup/models";

/**
 * PasswordField properties.
 */
export interface PasswordInputProps extends CypressProps {
  /** HTML name of the input */
  name: string;
  /** Text label to be show with the input */
  label?: string;
  dataTestId?: string;
}

/**
 * Simple password input with a button to show and hide the text.
 */
const PasswordField: FunctionComponent<PasswordInputProps> = ({
  name,
  label,
  dataTestId = "passwordinput-input",
  ...props
}: PasswordInputProps) => {
  const [show, setShow] = useState(false);

  const [field, meta] = useField(name);

  return (
    <FormGroup
      label={label}
      name={name}
      showValidation={!(!meta.touched || !meta.error)}
      validationMessage={meta.error}
    >
      <div className="form__input form__input--password">
        <input
          data-testid={dataTestId}
          type={show ? "text" : "password"}
          {...field}
          data-cy={props["data-cy"]}
        />

        <div
          className={clsx("password-input__append", {
            "password-input__append--show": show,
          })}
        >
          <button
            data-testid={"passwordinput-show-button"}
            type="button"
            tabIndex={-1}
            onClick={() => setShow(!show)}
          >
            {show ? <span>hide</span> : <span>show</span>}
          </button>
        </div>
      </div>
    </FormGroup>
  );
};

export default PasswordField;
