import useUserProfile from "hooks/account/useUserProfile";
import useStripe from "hooks/billing/useStripe";
import { useStripe as useStripeHook } from "@stripe/react-stripe-js";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Loader } from "@neurosolutionsgroup/components";
import { Col, Row } from "react-bootstrap";
import GreenCheckMark from "assets/GeneralIcons/green_checkmark.png";
import { Coupon, Price } from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

type Interval = "month" | "year";

interface MobileProductsProps {
  promoCode?: Coupon;
}

const MobileProducts: React.FC<MobileProductsProps> = ({ promoCode }) => {
  const { t } = useTranslation(["webappSettings"]);

  const [loading, setLoading] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState<Interval>();

  const {
    selectors: { prices, referralCoupon, localization },
  } = useStripe();

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const stripe = useStripeHook();

  const checkout = async (): Promise<void> => {
    if (prices && localization) {
      const price = prices.find(
        (p) =>
          p.interval === selectedInterval &&
          p.currency === localization.currency
      );

      if (selectedInterval && price) {
        setLoading(true);
        FirebaseAPI.Billing.Stripe.createCheckoutSession(
          price.id,
          localization,
          promoCode?.promoId ?? promoCode?.id
        ).then((response) => {
          if (stripe) {
            stripe.redirectToCheckout({
              sessionId: response.sessionId,
            });
          } else {
            console.error("No Stripe instance found.");
          }
        });
      }
    }
  };

  const getReferralInformation = useCallback((): Coupon | undefined => {
    return userProfile?.referralData?.referrer?.referrerName && referralCoupon
      ? referralCoupon
      : undefined;
  }, [userProfile, referralCoupon]);

  return prices.length > 0 && localization ? (
    <>
      {loading && <Loader />}
      <Row className="mt-3 justify-content-center">
        <Col md={5}>
          <MobileProductCard
            interval="month"
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            title={t("billing.producttype.monthly")}
            prices={prices}
            currency={localization.currency}
          />
        </Col>
        <Col md={5}>
          <MobileProductCard
            coupon={promoCode ?? getReferralInformation()}
            interval="year"
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            title={t("billing.producttype.yearly")}
            prices={prices}
            currency={localization.currency}
          />
        </Col>
      </Row>
      {!promoCode ? (
        <Row className="justify-content-center mt-3 mb-3 pb-5">
          <Col md={8}>
            <p className="text-center">
              <Trans i18nKey={"webappSettings:billing.discount"}>
                0 <a href="mailto:support@kairosgame.com">1</a> 2
              </Trans>
            </p>
          </Col>
        </Row>
      ) : null}
      <button onClick={() => checkout()} className="mobile-products__checkout">
        {t("billing.buy")}
      </button>
    </>
  ) : null;
};

export default MobileProducts;

interface MobileProductCardProps {
  coupon?: Coupon;
  interval: Interval;
  selectedInterval?: Interval;
  setSelectedInterval: (interval: Interval) => void;
  title: string;
  prices: Price[];
  currency: string;
}

const MobileProductCard: React.FC<MobileProductCardProps> = ({
  coupon,
  interval,
  selectedInterval,
  setSelectedInterval,
  title,
  prices,
  currency,
}) => {
  const { t, i18n } = useTranslation(["webappSettings"]);

  const { getYearlyPrice } = useStripe().actions;

  const getYearlyPriceWithCoupon = () => getYearlyPrice(currency, coupon);

  const price = () => {
    if (interval === "year") {
      return getYearlyPriceWithCoupon();
    }

    return prices.find(
      (p) => p.interval === interval && p.currency === currency
    );
  };

  return (
    <div
      className={
        "mobile-product-card mb-2" +
        (" mobile-product-card--" + interval) +
        (selectedInterval === interval ? " mobile-product-card--selected" : "")
      }
      onClick={() => setSelectedInterval(interval)}
    >
      <div className="mobile-product-card__popular-tag">
        <span>
          {t("webappSettings:billing.popular") +
            (coupon ? " - " + t("webappSettings:billing.couponApplied") : "")}
        </span>
      </div>
      <div className="mobile-product-card__content">
        <Row>
          <Col xs={10}>
            <h4 className="text-left mb-0">{title}</h4>
            <span className="price__amount">
              $
              {(
                (price()?.unitAmount ?? 0) /
                100 /
                (interval === "year" ? 12 : 1)
              ).toLocaleString(i18n.language, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>
            <span className="price__interval">
              {t(
                "webappSettings:billing.interval." +
                  (interval === "year" ? "yearly" : "monthly")
              )}
            </span>
            &nbsp;-&nbsp;
            <span>
              {interval === "year"
                ? t("webappSettings:billing.billing.yearly")
                : t("webappSettings:billing.billing.monthly")}
            </span>
          </Col>
          <Col xs={2}>
            <div
              className={
                "mt-2 mobile-product-card__radio" +
                (selectedInterval === interval
                  ? " mobile-product-card__radio--checked"
                  : "")
              }
            />
          </Col>
        </Row>
        {selectedInterval === interval ? (
          <div className="mt-0 d-flex flex-column">
            <h5 className="mb-2 marketing-title text-left">
              {t("billing.marketing.title")} :
            </h5>

            <div className="mx-auto">
              <div className="d-flex">
                <div className="price-card-footer-checkmark-img">
                  <img src={GreenCheckMark} alt="Checkmark" />
                </div>
                <p>{t("billing.marketing.point.one")}</p>
              </div>

              <div className="d-flex">
                <div className="price-card-footer-checkmark-img">
                  <img src={GreenCheckMark} alt="Checkmark" />
                </div>
                <p>{t("billing.marketing.point.two")}</p>
              </div>

              <div className="d-flex">
                <div className="price-card-footer-checkmark-img">
                  <img src={GreenCheckMark} alt="Checkmark" />
                </div>
                <p>{t("billing.marketing.point.three")}</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
