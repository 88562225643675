import React from "react";
import { useTranslation } from "react-i18next";
import GreenCheckMark from "assets/GeneralIcons/green_checkmark.png";
import { Button } from "react-bootstrap";
import AvatarAssets from "assets/avatars";
import useWindowDimensions from "hooks/useWindowDimensions";

interface FreeAccountProps {
  onSubmit: VoidFunction;
  interval: "year" | "month";
}

const FreeAccount: React.FC<FreeAccountProps> = ({ onSubmit, interval }) => {
  const ROOT_CLASS = "free-account";

  const { t } = useTranslation(["webappSettings"]);

  const { breakpoints } = useWindowDimensions();

  return breakpoints.md ? (
    <div className={ROOT_CLASS}>
      <h2>
        {interval === "year"
          ? t("billing.free.title.year")
          : t("billing.free.title.month")}
      </h2>
      <span className={ROOT_CLASS + "__subtitle"}>
        {interval === "year"
          ? t("billing.free.activated.year")
          : t("billing.free.activated.month")}
      </span>
      <div className="my-3 d-flex flex-column">
        <p className="mb-3 marketing-title">{t("billing.marketing.title")} :</p>

        <div className="mx-auto">
          <div className="d-flex">
            <div className="price-card-footer-checkmark-img">
              <img src={GreenCheckMark} alt="Checkmark" />
            </div>
            <p>{t("billing.marketing.point.one")}</p>
          </div>

          <div className="d-flex">
            <div className="price-card-footer-checkmark-img">
              <img src={GreenCheckMark} alt="Checkmark" />
            </div>
            <p>{t("billing.marketing.point.two")}</p>
          </div>

          <div className="d-flex">
            <div className="price-card-footer-checkmark-img">
              <img src={GreenCheckMark} alt="Checkmark" />
            </div>
            <p>{t("billing.marketing.point.three")}</p>
          </div>
        </div>
      </div>
      <Button onClick={onSubmit} className={ROOT_CLASS + "__button"}>
        {t("billing.free.action")}
      </Button>
      <img
        src={AvatarAssets.ThumbsUp}
        alt=""
        className={ROOT_CLASS + "__avatar"}
      />
    </div>
  ) : (
    <>
      <div
        className={
          ROOT_CLASS +
          "--mobile mobile-product-card mobile-product-card--selected mobile-product-card--free"
        }
      >
        <div className="mobile-product-card__popular-tag">
          <span>
            {interval === "year"
              ? t("billing.free.mobile.year")
              : t("billing.free.mobile.month")}
          </span>
        </div>
        <div className="mobile-product-card__content">
          <div className="mt-0 d-flex flex-column">
            <h5 className="mb-2 marketing-title text-left">
              {t("billing.marketing.title")} :
            </h5>

            <div className="mx-auto">
              <div className="d-flex">
                <div className="price-card-footer-checkmark-img">
                  <img src={GreenCheckMark} alt="Checkmark" />
                </div>
                <p>{t("billing.marketing.point.one")}</p>
              </div>

              <div className="d-flex">
                <div className="price-card-footer-checkmark-img">
                  <img src={GreenCheckMark} alt="Checkmark" />
                </div>
                <p>{t("billing.marketing.point.two")}</p>
              </div>

              <div className="d-flex">
                <div className="price-card-footer-checkmark-img">
                  <img src={GreenCheckMark} alt="Checkmark" />
                </div>
                <p>{t("billing.marketing.point.three")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"mt-2 " + ROOT_CLASS + "__mobile-success"}>
        <img
          src={AvatarAssets.GroupCelebration}
          alt={
            interval === "year"
              ? t("billing.free.activated.year")
              : t("billing.free.activated.month")
          }
        />
      </div>
      <button onClick={onSubmit} className="mobile-products__checkout">
        {t("billing.free.action")}
      </button>
    </>
  );
};

export default FreeAccount;
