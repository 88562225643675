import React from "react";
import { useTranslation } from "react-i18next";

//  Form.
import { Formik, Form } from "formik";
import * as Yup from "yup";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";

//  Components.
import { BevelButton, TextInput } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import useUserProfile from "hooks/account/useUserProfile";
import { Loader } from "@neurosolutionsgroup/components";

const AccountSettings = (): JSX.Element => {
  //  Localization.
  const { t } = useTranslation(["webappSettings", "webappBase"]);

  const {
    selectors: { userProfile },
    actions: { updateUserProfile },
  } = useUserProfile();

  return (
    <div>
      <PlainCard className="p-3">
        <h2 className="text-left mb-3">{t("account.title")}</h2>

        <Formik
          initialValues={{
            name: userProfile?.firstName ?? "",
            email: userProfile?.email ?? "",
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            name: Yup.string().required(t("webappBase:forms.errors.required")),
            email: Yup.string()
              .email(t("webappBase:forms.errors.invalidemail"))
              .required(t("webappBase:forms.errors.required")),
          })}
          onSubmit={async (values) => {
            try {
              await updateUserProfile({
                firstName: values.name,
                email: values.email,
              });

              store.addNotification(
                successNotification(
                  t("account.success.title"),
                  t("account.success.message")
                )
              );
            } catch (err) {
              store.addNotification(
                dangerNotification(
                  t("webappBase:forms.errors.title"),
                  t("webappBase:forms.errors.general") +
                    ": " +
                    t("webappBase:forms.errors." + err)
                )
              );
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting ? <Loader /> : null}
              <TextInput
                name="firstName"
                type="text"
                label={t("account.settings.firstname.label")}
              />
              <TextInput
                name="email"
                type="text"
                label={t("account.settings.email.label")}
              />
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="rgba(230, 156, 44, 1)"
                >
                  <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                </svg>{" "}
                {t("account.settings.email.note")}
              </p>
              <BevelButton
                type="submit"
                className="btn btn-primary w-100"
                disabled={isSubmitting}
              >
                {t("account.submit")}
              </BevelButton>
            </Form>
          )}
        </Formik>
      </PlainCard>
    </div>
  );
};

export default AccountSettings;
