import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BevelButton, PasswordInput, TextInput } from "components/inputs";
import RegistrationCheckboxes from "./RegistrationCheckboxes";

interface Props {
  isSubmitEnabled: boolean;
  onSubmit: (email: string, password: string, name?: string) => void;
  referral: boolean;
}

const RegistrationFormComponent = ({
  isSubmitEnabled,
  onSubmit,
  referral,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const email = new URLSearchParams(window.location.search).get("email");

  const blockName = "registration";

  return (
    <Formik
      initialValues={{
        email: email ?? "",
        password: "",
        passwordconfirm: "",
        name: "",
        termsAccepted: false,
        politicsAccepted: false,
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("fields.email.errors.invalid"))
          .required(t("fields.generic.errors.required")),
        password: Yup.string()
          .min(6, t("fields.password.errors.length"))
          .max(100, t("fields.password.errors.length"))
          .required(t("fields.generic.errors.required")),
        passwordconfirm: Yup.string()
          .required(t("fields.generic.errors.required"))
          .test(
            "passwords-match",
            t("fields.passwordconfirm.errors.match"),
            function (value) {
              return this.parent.password === value;
            }
          ),
        name: referral
          ? Yup.string()
              .min(3, t("fields.firstName.errors.length"))
              .max(100, t("fields.firstName.errors.length"))
              .required(t("fields.generic.errors.required"))
          : Yup.string(),
        termsAccepted: Yup.boolean().isTrue(),
        politicsAccepted: Yup.boolean().isTrue(),
      })}
      onSubmit={async (values) => {
        onSubmit(
          values.email,
          values.password,
          referral ? values.name : undefined
        );
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={blockName + "__form"}>
            <TextInput
              label={t("fields.email.label")}
              name="email"
              type="email"
              data-cy="registration-email-input"
            />
            <PasswordInput
              label={t("fields.password.label")}
              name="password"
              data-cy="registration-password-input"
            />
            <PasswordInput
              label={t("fields.passwordconfirm.label")}
              name="passwordconfirm"
              data-cy="registration-password-confirm-input"
            />
            {referral ? (
              <>
                <TextInput
                  label={t("fields.firstName.label")}
                  name="name"
                  type="text"
                />
              </>
            ) : null}

            <RegistrationCheckboxes setFieldValue={setFieldValue} />

            <div className={blockName + "__button-group"}>
              <BevelButton
                className={"btn btn-primary w-100 can_be_disabled"}
                type="submit"
                disabled={
                  !isSubmitEnabled ||
                  !values.politicsAccepted ||
                  !values.termsAccepted
                }
                onClick={() => {
                  return true;
                }}
                data-cy="registration-submit-button"
              >
                {t("pages.registration.submit")}
              </BevelButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegistrationFormComponent;
